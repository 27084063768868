.App {
  text-align: center;
}

html,
body {
  height: 100%;
  background-color: #0d192b;
}
body > div:nth-child(1) {
  background-color: #0d192b;
  height: 100vh;
}
#root {
  height: 100%;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.faq-section p {
  margin-top: 10px;
  margin-bottom: 10px;
}
.faq-question-li {
  list-style-type: disc;
  margin-bottom: 10px;
}
ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1 em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
.faq-section a {
  color: #3b82f6;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-item img {
  height: 310px;
}

@media only screen and (max-width: 600px) {
  .carousel-item img {
    height: 150px;
  }
}

.main_logo {
  width: 100px;
}

@media only screen and (max-width: 600px) {
  .main_logo {
    height: 100px;
    width: 100px;
  }
}

.main-header {
  font-size: 2.25rem;
  line-height: 2.25rem;
}
.sub-header {
  font-size: 24px;
}

@media only screen and (max-width: 600px) {
  .main-header {
    font-size: 26px;
  }
  .sub-header {
    font-size: 20px;
  }
}

/* Tab content - closed */
.tab-content {
  max-height: 0;
  -webkit-transition: max-height 0.35s;
  -o-transition: max-height 0.35s;
  transition: max-height 0.35s;
}
/* :checked - resize to full height */
.tab2 input:checked ~ .tab-content {
  max-height: 120vh;
}
/* Label formatting when open */
.tab2 input + label {
  padding: 1.25rem; /*.p-5*/
}
/* Icon */
.tab2 label::after {
  float: right;
  right: 0;
  top: 0;
  display: block;
  width: 24px;
  height: 24px;
  text-align: center;
  -webkit-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
  color: white !important;
}
/* Icon formatting - closed */
.tab2 input[type='checkbox'] + label::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='text-white bg-blue-500 w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  border-radius: 9999px; /*.rounded-full */
  background-color: #3b82f6;
  color: #f8fafc; /*.text-grey-lightest*/
  min-width: 24px;
}

.tab2 input[type='radio'] + label::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='w-6 h-6' fill='none' color='white' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M12 6v6m0 0v6m0-6h6m-6 0H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  border-radius: 9999px; /*.rounded-full */
  background-color: #3b82f6;
  color: #f8fafc; /*.text-grey-lightest*/
  font-size: 26px;
  min-width: 24px;
}

/* Icon formatting - open */
.tab2 input[type='checkbox']:checked + label::after {
  content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' class='text-gray-400 bg-gray w-6 h-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M18 12H6' /%3E%3C/svg%3E");
  font-weight: bold; /*.font-bold*/
  border-radius: 9999px; /*.rounded-full */
  background-color: #a0a0a0;
  color: #f8fafc; /*.text-grey-lightest*/
  font-size: 16px;
  min-width: 24px;
}

.tab2 input[type='radio']:checked + label::after {
  transform: rotateX(180deg);
  background-color: #3b82f6;
  color: #f8fafc; /*.text-grey-lightest*/
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked ~ .control-1 ~ .carousel-indicators li:nth-child(1) .carousel-bullet,
#carousel-2:checked ~ .control-2 ~ .carousel-indicators li:nth-child(2) .carousel-bullet,
#carousel-3:checked ~ .control-3 ~ .carousel-indicators li:nth-child(3) .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

video {
  min-height: 490px;
}
